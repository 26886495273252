import React from 'react';
import styled from 'styled-components';
import useForm from '../../utils/useForm';

export default function EngineerCodesPage() {
  const { inputs, handleChange } = useForm({
    password: '',
  });

  async function handleFormSubmit(event) {
    event.preventDefault();
    console.log('submit the form');
  }

  return (
    <CodesStyles className="container">
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <div className="form-header">
          <p>Log In</p>
        </div>

        <label htmlFor="">
          <p>Please enter your password</p>
          <input
            type="password"
            name="password"
            value={inputs.password}
            onChange={handleChange}
            required
          />
        </label>

        <button type="submit">Log In</button>
      </form>
    </CodesStyles>
  );
}

const CodesStyles = styled.div`
  padding-top: 80px;
  form {
    margin: 0 auto;
    max-width: 500px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    .form-header {
      background: var(--accent);
      color: var(--white);
      padding: 5px;
      p {
        margin: 0;
        color: var(--white);
        font-weight: 600;
        font-size: 18px;
      }
    }
    input {
      font-size: 18px;
      padding: 7px 10px;
    }
    button {
      outline: none;
      border: none;
      color: white;
      background-color: var(--accent);
      padding: 10px 30px;
      margin: 20px 0;
      font-family: 'Montserrat', sans-serif;
    }
  }
`;
